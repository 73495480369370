// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.client[data-v-2cadef14] {\n  display: table;\n  width: 100%;\n  border-top: 1px solid #f4f5f7;\n  font-size: 12px;\n}\n.client__cell[data-v-2cadef14] {\n  position: relative;\n  display: table-cell;\n  overflow: hidden;\n  max-width: 35px;\n  padding: 12px 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.client__cell--name[data-v-2cadef14] {\n  width: 145px;\n  padding-left: 15px;\n}\n.client__cell--email[data-v-2cadef14] {\n  width: 120px;\n}\n.client__cell--edit[data-v-2cadef14] {\n  width: 47px;\n  padding: 0;\n  text-align: center;\n}\n.client__cell--delete[data-v-2cadef14] {\n  width: 43px;\n  padding: 0 15px;\n}\n.client__remove-btn[data-v-2cadef14] {\n  display: block;\n  width: 12px;\n  height: 12px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 9px auto;\n  background-repeat: no-repeat;\n}\n@media (max-width: 767px) {\n.client[data-v-2cadef14],\n  .client__cell[data-v-2cadef14] {\n    display: block;\n    width: 100%;\n    max-width: initial;\n    padding: 0;\n}\n.client[data-v-2cadef14] {\n    padding: 5px;\n}\n.client__cell[data-v-2cadef14] {\n    padding: 3px 10px;\n}\n.client__cell--tel[data-v-2cadef14],\n  .client__cell--edit[data-v-2cadef14],\n  .client__cell--delete[data-v-2cadef14] {\n    display: table-cell;\n    width: auto;\n}\n.client__cell--tel[data-v-2cadef14] {\n    width: 100%;\n}\n}\n", ""]);
// Exports
module.exports = exports;
