(<template>
  <div class="client">
    <p class="client__cell client__cell--name">{{ fullName }}</p>
    <p class="client__cell client__cell--enterprise">{{ client.orgName }}</p>
    <p class="client__cell client__cell--email">{{ client.email }}</p>
    <p class="client__cell client__cell--org">{{ orgNumber }}</p>
    <a :href="`tel:${fullPhone}`"
       class="sk-link client__cell client__cell--tel js-copy-to-clipboard">{{ fullPhone }}</a>
    <p class="client__cell client__cell--edit">
      <button class="sk-btn sk-link sk-btn--like-link client__edit-btn"
              @click="editClient">{{ $gettext('Edit') }}</button>
    </p>
    <p class="client__cell client__cell--delete">
      <button class="client__remove-btn"
              @click="delCurrentClient"></button>
    </p>
  </div>
</template>)

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    props: {
      client: {
        type: Object,
        default() {
          return {};
        }
      },
      clientIndex: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      orgNumber() {
        const template = this.$gettext('Org. %{n}');
        return this.$gettextInterpolate(template, {n: this.client.orgNumber});
      },
      fullPhone() {
        return `${this.client.phoneCode}${this.client.phoneNumber}`;
      },
      fullName() {
        return `${this.client.firstName} ${this.client.lastName}`;
      }
    },
    methods: {
      ...mapActions('TTManageClientsStore', ['deleteClient']),
      delCurrentClient() {
        this.$store.commit('GlobalStore/startProgress');
        this.deleteClient({
          uid: this.userUid,
          clientId: this.client.id,
          clientIndex: this.clientIndex
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        }).catch(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      editClient() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-client',
          data: {
            title: this.$gettext('Client information'),
            description: this.$gettext('Register your customers information for assignments that you have conducted outside of the platform.'),
            currentClient: this.client,
            context: this
          }
        });
      }
    }
  };
</script>

<style scoped>
  .client {
    display: table;
    width: 100%;
    border-top: 1px solid #f4f5f7;
    font-size: 12px;
  }

  .client__cell {
    position: relative;
    display: table-cell;
    overflow: hidden;
    max-width: 35px;
    padding: 12px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .client__cell--name {
    width: 145px;
    padding-left: 15px;
  }

  .client__cell--email {
    width: 120px;
  }

  .client__cell--edit {
    width: 47px;
    padding: 0;
    text-align: center;
  }

  .client__cell--delete {
    width: 43px;
    padding: 0 15px;
  }

  .client__remove-btn {
    display: block;
    width: 12px;
    height: 12px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
    background-position: 50% 50%;
    background-size: 9px auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 767px) {
    .client,
    .client__cell {
      display: block;
      width: 100%;
      max-width: initial;
      padding: 0;
    }

    .client {
      padding: 5px;
    }

    .client__cell {
      padding: 3px 10px;
    }

    .client__cell--tel,
    .client__cell--edit,
    .client__cell--delete {
      display: table-cell;
      width: auto;
    }

    .client__cell--tel {
      width: 100%;
    }
  }
</style>
