import { render, staticRenderFns } from "./ClientItem.vue?vue&type=template&id=2cadef14&scoped=true&"
import script from "./ClientItem.vue?vue&type=script&lang=js&"
export * from "./ClientItem.vue?vue&type=script&lang=js&"
import style0 from "./ClientItem.vue?vue&type=style&index=0&id=2cadef14&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cadef14",
  null
  
)

export default component.exports